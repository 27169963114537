import Relation from '@/dc-it/types/Relation'
import Destination from '@/dc-it/models/Destination'
import EventType from '@/dc-it/models/EventType'
import Model from './Model'
import Field from '../types/Field'
import Select from '../types/Select'
import helpers from '../services/helpers'

export default class Event extends Model {
    endpoint = 'events';

    related = ['event_type', 'destination'];

    statuses = {
      0: 'Cancelado',
      1: 'Pendiente de pago',
      2: 'Confirmado',
    };

    paymentMethods = {
      1: 'Completo',
      2: 'Parcial',
    };

    genders = {
      0: 'Ambos',
      1: 'Mujer',
      2: 'Hombre',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
      new Field('lat', 'Latitud'),
      new Field('lng', 'Longitud'),
      new Field('address', 'Dirección'),
      new Field('date', 'Fecha', 'date'),
      new Field('time', 'Hora', 'time'),
      new Field('duration', 'Duración'),
      new Select('status', 'Estado', this.statuses),
      new Select('payment_method', 'Método de pago', this.paymentMethods),
      new Field('public_total', 'Total público', 'number').applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('net_total', 'Total neto', 'number').applyMask(val => `$${helpers.numberFormat(val)}`),
      new Select('gender_required', 'Género requerido', this.genders),
      new Field('quantity', 'Cantidad', 'number'),
      new Relation('destination_id', 'Destino', Destination, 'name'),
      new Relation('event_type_id', 'Tipo de evento', EventType, 'name'),
    ];

    clone = () => Event;
}

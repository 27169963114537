import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import ModelAgentSubscriptionPlan from '@/dc-it/models/ModelAgentSubscriptionPlan'
import Relation from '@/dc-it/types/Relation'
import Select from '@/dc-it/types/Select'
import Payment from '@/dc-it/models/Payment'

export default class ModelAgentSubscription extends Model {
    endpoint = 'model_agent_subscriptions'

    related = ['model_agent_subscription_plan', 'payment']

    status = {
      0: 'Cancelado',
      1: 'Pendiente',
      2: 'Activo',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('model_agent_subscription_plan', 'Plan de suscripción', ModelAgentSubscriptionPlan, 'name'),
      new Field('start_date', 'Fecha de inicio', 'date'),
      new Field('end_date', 'Fecha de fin', 'date'),
      new Relation('payment', 'Pago', Payment, 'status'),
      new Select('status', 'Estado', this.status),
    ]

    clone = () => ModelAgentSubscription
}

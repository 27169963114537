import Relation from '@/dc-it/types/Relation'
import Event from '@/dc-it/models/Event'
import Model from './Model'
import Field from '../types/Field'
import Select from '../types/Select'

export default class Payment extends Model {
    endpoint = 'payments';

    related = ['event'];

    statuses = {
      0: 'Cancelado',
      1: 'Pendiente de pago',
      2: 'Confirmado',
    };

    paymentMethods = {
      1: 'PayPal',
      2: 'Conekta',
      3: 'Stripe',
      4: 'Efectivo',
    };

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('event_id', 'Evento', Event, 'name'),
      new Field('charge_id', 'ID del cargo'),
      new Field('amount', 'Monto'),
      new Select('status', 'Estado', this.statuses, 0),
      new Select('payment_method', 'Método de pago', this.paymentMethods, 4),
    ];

    clone = () => Payment;
}
